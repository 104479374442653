import React, {useState } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { useLanguage } from './LanguageContext';
import stringsLatin from './ConstantsLatin';
import stringsCyrlic from './ConstantsCyrilic';
import { Link } from 'react-router-dom';
import tickDetectLogo from './img/TickDetectIcon.ico'
import phone from './img/phone-call.png'
import emailLogo from './img/email.png'
import logo from './img/TickDetectLogo.png';
import logoFooter from './img/TickDetectLogoFooter.png';
import logoInstitute from './img/logo-institute.png';
import logoInstituteCyr from './img/logo-institute-cr.png';
import './Contact.css';
import 'react-toastify/dist/ReactToastify.css';
import hamburger from './img/hamburger.svg';

function Contact() {
  function clickNav(){
    toggleLanguage();
    toggleOffcanvas();
}
  const [isOpen, setIsOpen] = useState(false);
  const toggleOffcanvas = () => {
    setIsOpen(!isOpen);
};
    const { language, toggleLanguage } = useLanguage();
    const text = language === 'latin' ? stringsLatin : stringsCyrlic;
    const footerLogo = language =='latin' ? logoInstitute : logoInstituteCyr;

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();


    const handleSubmit = async (event) => {
      event.preventDefault()
      const url = 'https://krpelji.nivns.edu.rs/messages';
      console.log(name);
      console.log(email);
      console.log(message);
      const payload = {
          name: name,
          email: email,
          message: message
      };
      console.log(JSON.stringify(payload))
      const response = await fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
      });
      
      if (response.status !== 200 && response.status !== 201) {
          console.error(`HTTP error! status: ${response.status}`);
          toast.error(text.errorSubmit);
          setName('');
          setEmail('');
          setMessage('');
      } else {
          toast.success(text.successSubmit);
          setName('');
          setEmail('');
          setMessage('');
      }
      
  };
    return (
        <div className="contact-page">
                <Helmet>
                    <title>Tick Detect | {text.navbarContact}</title>
                    <link rel="icon" type="image/x-icon" href={tickDetectLogo} />
                </Helmet>   
            <div className="header-and-content">
            <div>
            <nav className="navbar navbar-expand-sm navbar-light nav-contact">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="Tick Detect Logo" />
                </a>
                <div className="navbar-toggler" onClick={toggleOffcanvas}
                        aria-controls="offcanvasNavbar" aria-expanded={isOpen} aria-label="Toggle navigation">
                    <img className="burger" src={hamburger}></img>
                </div>

                <div className={`offcanvas offcanvas-start ${isOpen ? 'show' : ''} d-sm-none`} tabIndex="-1" id="offcanvasNavbar"
                     aria-labelledby="offcanvasNavbarLabel" style={{visibility: isOpen ? 'visible' : 'hidden'}}>
                    <div className="offcanvas-header">
                        <div  className="btn-close btn-close-white" onClick={toggleOffcanvas} aria-label="Close"></div>
                    </div>
                    <div className="offcanvas-body">
  <div className="footer-upper nav-mob-header">
    
    <div className="footer-logo">
    <a  href="/">
    <img src={logoFooter} className="nav-brand-mob" alt="Tick Detect Logo" />
    </a>    
        </div>
    <div className="footer-links nav-mobile">
<p><Link to="/about" className="footer-link nav-font">{text.navbarAbout}</Link></p>
<p><Link to="/contact" className="footer-link contact-link nav-font">{text.navbarContact}</Link></p>
<button className="nav-item nav-link nav-lang-mob" onClick={clickNav}>
                        LAT <span className="pipe-color">|</span> ЋИР
                    </button>
</div>
<div className="canvas-off"></div>


    <div className="footer-contact nav-mob ">
    <div className="contact-item ">
    <img className="icon" src={emailLogo} alt="email" />
    
    <p className="nav-font">pronadjikrpelja@niv.ns.ac.rs</p>
  </div>
  <div className="contact-item contact-phone">
    <img className="icon" src={phone} alt="phone" />
    <p>+381 21 489 53 30</p>
  </div>
</div>

    <div className="footer-institute-logo nav-inst-logo">
      <img src={footerLogo} alt="Institut Logo" />
    </div>
    
                        </div>
                    </div>
                </div>

                <div className="collapse navbar-collapse justify-content-end d-none d-sm-block" id="navbarSupportedContent">
                <div className="navbar-nav ms-auto">
                    <Link className="nav-item nav-link" to="/about">{text.navbarAbout}</Link>
                    <Link className="nav-item nav-link" to="/contact">{text.navbarContact}</Link>
                    <button className="nav-item nav-link" onClick={toggleLanguage}>
                        LAT <span className="pipe-color">|</span> ЋИР
                    </button>
                </div>
                </div>
            </div>
        </nav>
            </div>
            <div className="connect-question-div contact-question">
                    <div className="connect-question-text-div question-text">
                        <h3>{text.questionBoxTitle}</h3>
                        <p>{text.questionBoxDescription}</p>
                    </div>
                    <form className="connect-question-form-div question-text"  onSubmit={handleSubmit}>
                    <input value={name} onChange={(e) => setName(e.target.value)} required type="text"  minlength="2" maxlength="100" className="line-input input-contact" placeholder={text.questionBoxNameLabel}/>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} required type="email" minlength="2" maxlength="100" className="line-input input-contact" placeholder={text.questionBoxEmailLabel}/>
                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} required type="text" maxRows="5" maxlength="500" className="line-input message-input input-contact" placeholder={text.questionBoxMessageLabel}/>
                    <button type="submit" className="question-button contact-button">{text.questionBoxSubmitButton}</button>

                    </form>
                    </div>
            </div>
            <footer className="footer-container">
  <div className="footer-upper">
    <div className="footer-logo">
    <a  href="/">
    <img src={logoFooter} alt="Tick Detect Logo" />
    </a>   
        </div>
    <div className="footer-contact">
  <div className="contact-item">
    <img className="icon" src={emailLogo} alt="email" />
    <p>pronadjikrpelja@niv.ns.ac.rs</p>
  </div>
  <div className="contact-item contact-phone">
    <img className="icon" src={phone} alt="phone" />
    <p>+381 21 489 53 30</p>
  </div>
</div>
<div className="footer-links">
<p><Link to="/about" className="footer-link">{text.navbarAbout}</Link></p>
<p><Link to="/contact" className="footer-link contact-link">{text.navbarContact}</Link></p>
</div>
    <div className="footer-institute-logo">
      <img src={footerLogo} alt="Institut Logo" />
    </div>
  </div>
  <div className="footer-line"></div>
  <div className="footer-lower">
    <p>© 2024 Tick Detect</p>
    <p><Link to="http://weareshortcut.com" className="footer-shortcut">Design and development:<span className="green-title separator"><b>Shortcut</b></span></Link></p>
  </div>
</footer>
        </div>
    );
}

export default Contact;