import React, { createContext, useContext, useEffect, useState } from 'react';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(() => {
        // Retrieve the language from local storage, default to 'latin'
        const savedLanguage = localStorage.getItem('languagePreference');
        return savedLanguage || 'cyrillic';
    });

    const toggleLanguage = () => {
        setLanguage((prevLanguage) => {
            const newLanguage = prevLanguage === 'latin' ? 'cyrillic' : 'latin';
            localStorage.setItem('languagePreference', newLanguage); // Save the new language preference
            return newLanguage;
        });
    };

    // This effect will run only once, when the component mounts, ensuring
    // that if there's a language preference saved, it's respected from the start.
    useEffect(() => {
        const savedLanguage = localStorage.getItem('languagePreference');
        if (savedLanguage) {
            setLanguage(savedLanguage);
        }
    }, []);

    return (
        <LanguageContext.Provider value={{ language, toggleLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
