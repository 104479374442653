import React, {useState } from 'react';
import { Helmet } from 'react-helmet';
import aboutBoxFirst from './img/Vector.svg'
import aboutBoxSecond from './img/about-box-second.svg'
import aboutBoxThird from './img/about-box-third.svg'
import { useLanguage } from './LanguageContext';
import stringsLatin from './ConstantsLatin';
import stringsCyrlic from './ConstantsCyrilic';
import { Link } from 'react-router-dom';
import tickDetectLogo from './img/TickDetectIcon.ico'
import phone from './img/phone-call.png'
import emailLogo from './img/email.png'
import logo from './img/TickDetectLogo.png';
import logoFooter from './img/TickDetectLogoFooter.png';
import logoInstitute from './img/logo-institute.png';
import logoInstituteCyr from './img/logo-institute-cr.png';
import phoneImage from './img/Tick-App.png';
import aboutBackground from './img/about-background.png';
import './About.css';
import 'react-toastify/dist/ReactToastify.css';
import hamburger from './img/hamburger.svg';

function About() {
  function clickNav(){
    toggleLanguage();
    toggleOffcanvas();
}
  // Usage:
  const [isOpen, setIsOpen] = useState(false);
  const toggleOffcanvas = () => {
    setIsOpen(!isOpen);
};
    const { language, toggleLanguage } = useLanguage();
    const text = language === 'latin' ? stringsLatin : stringsCyrlic;
    const footerLogo = language =='latin' ? logoInstitute : logoInstituteCyr;
    const handleCardClick = () => {
        window.location.href = "https://krpelji.nivns.edu.rs/mobilni";
    }
    return (
        <div className="about-page">
        <Helmet>
            <title>Tick Detect | {text.navbarAbout}</title>
            <link rel="icon" type="image/x-icon" href={tickDetectLogo} />
        </Helmet>   
        <nav className="navbar navbar-expand-sm navbar-light bg-white fixed-top">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="Tick Detect Logo" />
                </a>
                <div className="navbar-toggler" onClick={toggleOffcanvas}
                        aria-controls="offcanvasNavbar" aria-expanded={isOpen} aria-label="Toggle navigation">
                    <img className="burger" src={hamburger}></img>
                </div>

                <div className={`offcanvas offcanvas-start ${isOpen ? 'show' : ''} d-sm-none`} tabIndex="-1" id="offcanvasNavbar"
                     aria-labelledby="offcanvasNavbarLabel" style={{visibility: isOpen ? 'visible' : 'hidden'}}>
                    <div className="offcanvas-header">
                        <div  className="btn-close btn-close-white" onClick={toggleOffcanvas} aria-label="Close"></div>
                    </div>
                    <div className="offcanvas-body">
  <div className="footer-upper nav-mob-header">
    
    <div className="footer-logo">
    <a  href="/">
    <img src={logoFooter} className="nav-brand-mob" alt="Tick Detect Logo" />
    </a>    
        </div>
    <div className="footer-links nav-mobile">
<p><Link to="/about" className="footer-link nav-font">{text.navbarAbout}</Link></p>
<p><Link to="/contact" className="footer-link contact-link nav-font">{text.navbarContact}</Link></p>
<button className="nav-item nav-link nav-lang-mob" onClick={clickNav}>
                        LAT <span className="pipe-color">|</span> ЋИР
                    </button>
</div>
<div className="canvas-off"></div>


    <div className="footer-contact nav-mob ">
    <div className="contact-item ">
    <img className="icon" src={emailLogo} alt="email" />
    
    <p className="nav-font">pronadjikrpelja@niv.ns.ac.rs</p>
  </div>
  <div className="contact-item contact-phone">
    <img className="icon" src={phone} alt="phone" />
    <p>+381 21 489 53 30</p>
  </div>
</div>

    <div className="footer-institute-logo nav-inst-logo">
      <img src={footerLogo} alt="Institut Logo" />
    </div>
    
                        </div>
                    </div>
                </div>

                <div className="collapse navbar-collapse justify-content-end d-none d-sm-block" id="navbarSupportedContent">
                <div className="navbar-nav ms-auto">
                    <Link className="nav-item nav-link" to="/about">{text.navbarAbout}</Link>
                    <Link className="nav-item nav-link" to="/contact">{text.navbarContact}</Link>
                    <button className="nav-item nav-link" onClick={toggleLanguage}>
                        LAT <span className="pipe-color">|</span> ЋИР
                    </button>
                </div>
                </div>
            </div>
        </nav>
    <div className="about-main-div">
        <div className="about-text-div">
        <h1>{text.aboutTitleOne}<span className="green-title">{text.aboutTitleTwo}</span>{text.aboutTitleThree}</h1>
        <p>{text.aboutParagraph}</p>
        <button onMouseDown={handleCardClick} className="question-button contact-button about-button">{text.jumbotronSlideTwoButton}</button>
        </div>
        <div className="about-picture-div">
        <img src={phoneImage} alt="Tick Detect App" />
        </div>
    </div>
    <div className="about-description-div">
    <div className="about-description-text">
    <p>
            <b>{text.aboutDescriptionParagraphOne}</b>
            {text.aboutDescriptionParagraphTwo}
            <br />
            <br />
            {text.aboutDescriptionParagraphBrake}
            <b>{text.aboutDescriptionParagraphThree} </b>
            {text.aboutDescriptionParagraphFour}
        </p>    </div>
    <div className="about-description-instruction">
        <h2>{text.aboutDescriptionInstruction}</h2>
    </div>

    <div className="about-wrapper">
    <div className="sez">
    <img className="about-pictures" src={aboutBoxFirst} alt="Icon Description" />
    <div className="about-box">
        <h5>{text.aboutBoxOneTitle}</h5>
        <p>{text.aboutBoxOneDescription}</p>
    </div>
    </div>
    <div className="sez">
    <img className="about-pictures" src={aboutBoxSecond} alt="Icon Description" />
    <div className="about-box">
    <h5>{text.aboutBoxTwoTitle}</h5>
        <p>{text.aboutBoxTwoDescription}</p>
    </div>
    </div>
    <div className="sez">
    <img className="about-pictures" src={aboutBoxThird} alt="Icon Description" />
    <div className="about-box">
    <h5>{text.aboutBoxThreeTitle}</h5>
        <p>{text.aboutBoxThreeDescription}</p>
    </div>        
    </div>
    </div>
    </div>
    <div className="important-div">
        <h2><span className="green-title">{text.importantTitlePartOne}</span>{text.importantTitlePartTwo}</h2>
        <p className="about-first-paragraph">{text.importantParagraphOne}</p>
        <p className="about-green-paragraph about-second-paragraph">{text.importantParagraphTwo}</p>
        <p className="about-third-paragraph">{text.importantParagraphThree}</p>
    </div>
    <footer className="footer-container">
  <div className="footer-upper">
    <div className="footer-logo">
    <a  href="/">
    <img src={logoFooter} alt="Tick Detect Logo" />
    </a>   
        </div>
    <div className="footer-contact">
  <div className="contact-item">
    <img className="icon" src={emailLogo} alt="email" />
    <p>pronadjikrpelja@niv.ns.ac.rs</p>
  </div>
  <div className="contact-item contact-phone">
    <img className="icon" src={phone} alt="phone" />
    <p>+381 21 489 53 30</p>
  </div>
</div>
<div className="footer-links">
<p><Link to="/about" className="footer-link">{text.navbarAbout}</Link></p>
<p><Link to="/contact" className="footer-link contact-link">{text.navbarContact}</Link></p>
</div>
    <div className="footer-institute-logo">
      <img src={footerLogo} alt="Institut Logo" />
    </div>
  </div>
  <div className="footer-line"></div>
  <div className="footer-lower">
    <p>© 2024 Tick Detect</p>
    <p><Link to="http://weareshortcut.com" className="footer-shortcut">Design and development:<span className="green-title separator"><b>Shortcut</b></span></Link></p>
  </div>
</footer>
</div>
    );
}

export default About;