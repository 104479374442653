const stringsCyrlic = {
    navbarAbout: "О апликацији",
    navbarContact: "Контакт",
    jumbotronTitle: "Један клик мења \nживот",
    jumbotronButton: "О апликацији",
    readMore: "Прочитајте више",
    jumbotronDescription: "Ова апликација је посвећена сузбијању крпеља\nи превенцији болести, које преносе крпељи!",
    jumbotronSlideOne: "Смањите могућност уједа крпеља\nтоком боравка у природи.",
    jumbotronSlideTwo: "Сазнајте све о\nЛајмској болести\nна једном месту",
    jumbotronSlideTwoButton: "Погледајте апликацију",
    jumbotronSlideThree: "Научите како\nпрепознати крпеље\nи како их сигурно\nуклонити.",
    videoSlideOneTitle: "Сазнајте све\nо крпељима",
    videoSlideOneDescription: "Сазнајте како да безбедно\nуклоните крпеља.",
    videoSlideTwoTitle: "Лајмска болест",
    videoSlideTwoDescription: "Сазнајте све о болестима\nкоје преносе крпељи и о\nпревентивним мерама\nзаштите.",
    videoSlideThreeTitle: "Tick Detect\nапликација",
    videoSlideThreeDescription: "Усликајте крпеља и сазнајте\nсве о њему.",
    applicationTitleOne: "1. Закачио вам се крпељ?",
    applicationDescriptionOne: "Правилно га скините или посетите најближу\nамбуланту да га скину.",
    applicationTitleTwo: "2. Идентификујте крпеља",
    applicationDescriptionTwo: "Фотографишите крпеља и пошаљите\nфотографију путем апликације.",
    applicationTitleThree: "3. Да ли је крпељ заражен?",
    applicationDescriptionThree: "Донесите или пошаљите поштом крпеља\nу нашу лабораторију, како бисмо\nурадили идентификацију врсте крпеља и\nутврдили да ли у њему има бактерија,\nкоје преносе Лајмску болест.\n(опционо)",
    applicationButton: "Сазнајте више",
    mapNumberOfUsers: "Број корисника апликације",
    mapNumberOfDetection: "Укупан број детектованих\nкрпеља",
    mapLegendTitle: "Семафор крпеља",
    mapLegendRed: "Подручје високог степена\nидентификације крпеља",
    mapLegendYellow: "Подручје средњег степена\nидентификације крпеља",
    mapLegendGreen: "Подручје ниског степена\nидентификације крпеља",
    mapLegendDescription: "Кликом на одређени округ можете видети\nукупан број крпеља по градовима.",
    preventionTitle: "Превентивне мере заштите",
    preventionDescription: "Сазнајте на који начин можете да заштитите себе,\nсвоје најближе и кућне љубимце током боравка\nна отвореном и у природи.",
    newsTitle: "Будите увек у току",
    newsDescription: "Пратите наш садржај на друштвеним мрежама и пронађите\nсве информације на једном месту.",
    cardOneTitle: "Правилно чување крпеља за тестирање",
    cardOneFirstDescription: "Ставите у малу бочицу са алкохолом",
    cardOneSecondDescription: "Ставите у замрзивач у затворену врећицу",
    cardTwoTitle: "Не заборави <b>да се прегледаш</b> након шетње у природи",
    cardThreeTitle: "Не ломи</br>крпеље</br>прстима",
    cardThreeFirstDescription: "На тај начин се можеш <b>изложити болестима,</b> које преносе крпељи.",
    cardThreeSecondDescription: "Буди безбедан/на и користи одговарајући <b>метод одлагања.</b>",
    cardFourTitle: "Сви воле пролеће",
    cardFourFirstDescription: "То значи да је и <b>сезона крпеља</b> званично почела!",
    cardFourSecondDescription: "Сазнај како да будеш безбедан/на и уживај у природи уз помоћ <b>Tick Detect</b> апликације",
    cardFiveTitle: "Детектуј крпеља",
    cardFiveDescription: "Сазнај све о крпељима на једном месту.",
    cardSixTitle: "Како правилно <b>уклонити крпеља</b>?",
    cardEightFirstDescription: "Апликација за ујед крпеља.",
    cardEightSecondDescription: "УСКОРО!",
    questionBoxTitle: "Имате\nпитање?",
    questionBoxDescription: "Попуните контакт форму и ми ћемо\nвам одговорити у најкраћем року.",
    questionBoxNameLabel: "    Име",
    questionBoxEmailLabel: "    Email",
    questionBoxMessageLabel: "    Порука",
    questionBoxSubmitButton: "Пошаљите поруку",
    errorSubmit: "Грешка приликом слања поруке, молимо Вас покушајте касније!",
    successSubmit: "Успешно сте послали поруку!",
    aboutTitleOne: "Мисија ",
    aboutTitleTwo: "Tick Detect\n",
    aboutTitleThree: "апликације",
    aboutParagraph: "•    Прикупљање информација о крпељима и њиховим\n     стаништима;\n\n•    Идентификација подручја где су крпељи чести и изложеност\n     људи крпељима;\n\n•    Информисање становништва о крпељима, правилном\n     уклањању и Лајмској болести;\n\n•    Проучавање ризика од Лајмске болести;\n\n•    Мере за смањење вероватноће уједа крпеља током\n      боравка у природи.",
    importantTitlePartOne: "Важно је ",
    importantTitlePartTwo: "не доносити брзе закључке.",
    importantParagraphOne: "Ујед крпеља не мора бити катастрофан, предузимање одговарајућих\nмера је кључно. Проверавајте се после сваког боравка напољу, туширајте се и перите косу.",
    importantParagraphTwo: "Ако пронађете крпеља, донесите га нама на анализу.",
    importantParagraphThree: "Уколико је крпељ инфициран, терапија антибиотицима у прва два месеца може спречити ширење\nбактерија у вашем организму и појаве симптома Лајмске болести.",
    aboutDescriptionParagraphOne: " Идеја ове апликације",
    aboutDescriptionParagraphTwo: " јесте да се све значајне информације концентришу\n на једном месту, пружајући корисницима основно знање и мир. Овде\n можете сазнати о све крпељима, њиховим навикама, правилном\n уклањању крпеља и како утврдити да ли је крпељ заражен.",
    aboutDescriptionParagraphBrake: " Да бисте помогли другима у околини, уколико пронађете крпеља на\n себи, својој деци или љубимцу,",
    aboutDescriptionParagraphThree: " пратите кораке уклањања, направите\n фотографију и пошаљите је путем апликације. ",
    aboutDescriptionParagraphFour: "Ове информације могу\n упозорити људе да буду опрезнији у тој области. Такође можете донети\n крпеља у нашу лабораторију ради идентификације врсте и утврђивања\n присуства инфекције.",    
    aboutDescriptionInstruction: "Кораци за\nкоришћење апликације",
    aboutBoxOneTitle: "Закачио Вам се крпељ?",
    aboutBoxOneDescription: "Правилно га скините или посетите\nнајближу амбуланту да га\nскину.",
    aboutBoxTwoTitle: "Идентификујте крпеља",
    aboutBoxTwoDescription: "Фотографишите крпеља и\nпошаљите фотографију\nпутем апликације.",
    aboutBoxThreeTitle: "Да ли је крпељ заражен?",
    aboutBoxThreeDescription: "Донесите или пошаљите поштом\nкрпеља у нашу лабораторију,\nкако бисмо утврдили да ли у\nњему има бактерија.",
    totalDetectedTicks: "Укупан број детектованих крпеља",
    seeMore: "Види више..."

};

export default stringsCyrlic;