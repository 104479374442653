const stringsLatin ={

    navbarAbout: "O aplikaciji",
    navbarContact: "Kontakt",
    jumbotronTitle: "Jedan klik menja\nživot",
    jumbotronButton: "O aplikaciji",
    readMore: "Pročitajte više",
    jumbotronDescription: "Ova aplikacija je posvećena suzbijanju krpelja\ni prevenciji bolesti, koje prenose krpelji!",
    jumbotronSlideOne: "Smanjite mogućnost ujeda krpelja\ntokom boravka u prirodi.",
    jumbotronSlideTwo: "Saznajte sve o\nLajmskoj bolesti\nna jednom mestu",
    jumbotronSlideTwoButton: "Pogledajte aplikaciju",
    jumbotronSlideThree: "Naučite kako\nprepoznati krpelje\ni kako ih sigurno\nukloniti.",
    videoSlideOneTitle: "Saznajte sve\no krpeljima",
    videoSlideOneDescription: "Saznajte kako da bezbedno\nuklonite krpelja.",
    videoSlideTwoTitle: "Lajmska bolest",
    videoSlideTwoDescription: "Saznajte sve o bolestima\nkoje prenose krpelji i o\npreventivnim merama\nzaštite.",
    videoSlideThreeTitle: "Tick Detect\naplikacija",
    videoSlideThreeDescription: "Uslikajte krpelja i saznajte\nsve o njemu.",
    applicationTitleOne: "1. Zakačio vam se krpelj?",
    applicationDescriptionOne: "Pravilno ga skinite ili posetite najbližu\nambulantu da ga skinu.",
    applicationTitleTwo: "2. Identifikujte krpelja",
    applicationDescriptionTwo: "Fotografišite krpelja i pošaljite\nfotografiju putem aplikacije.",
    applicationTitleThree: "3. Da li je krpelj zaražen?",
    applicationDescriptionThree: "Donesite ili pošaljite poštom krpelja\nu našu laboratoriju, kako bismo\nuradili identifikaciju vrste krpelja i\nutvrdili da li u njemu ima bakterija,\nkoje prenose Lajmsku bolest.\n(opciono)",
    applicationButton: "Saznajte više",
    mapNumberOfUsers: "Broj korisnika aplikacije",
    mapNumberOfDetection: "Ukupan broj detektovanih\nkrpelja",
    mapLegendTitle: "Semafor krpelja",
    mapLegendRed: "Područje visokog stepena\nidentifikacije krpelja",
    mapLegendYellow: "Područje srednjeg stepena\nidentifikacije krpelja",
    mapLegendGreen: "Područje niskog stepena\nidentifikacije krpelja",
    mapLegendDescription: "Klikom na određeni okrug možete videti\nukupan broj krpelja po gradovima.",
    preventionTitle: "Preventivne mere zaštite",
    preventionDescription: "Saznajte na koji način možete da zaštite sebe,\nsvoje najbliže i kučne ljubimce tokom boravka\nna otvorenom i u prirodi.",
    newsTitle: "Budite uvek u toku",
    newsDescription: "Pratite naš sadržaj na društvenim mrežama i pronađite\n sve informacije na jednom mestu.",
    cardOneTitle: "Pravilno čuvanje krpelja za testiranje",
    cardOneFirstDescription: "Stavite u malu bočicu sa alkoholom",
    cardOneSecondDescription: "Stavite u zamrzivač u zatvorenu vrećicu",
    cardTwoTitle: "Ne zaboravi <b>da se pregledaš </b> nakon šetnje u prirodi",
    cardThreeTitle: "Ne lomi</br>krpelje</br>prstima",
    cardThreeFirstDescription: "Na taj način se možeš <b>izložiti bolestima,</b> koje prenose krpelji.",
    cardThreeSecondDescription: "Budi bezbedan/na i koristi odgovarajuči <b>metod odlaganja.</b>",
    cardFourTitle: "Svi vole proleće",
    cardFourFirstDescription: "To znači da je i <b>sezona krpelja</b> zvanično počela!",
    cardFourSecondDescription: "Saznaj kako da budes bezbedan/na i uživaj u prirodi uz pomoć <b>Tick Detect</b> aplikacije",
    cardFiveTitle: "Detektuj krpelja",
    cardFiveDescription: "Saznaj sve o krpeljima na jednom mestu.",
    cardSixTitle: "Kako pravilno <b>ukloniti krpelja</b>?",
    cardEightFirstDescription: "Aplikacija za ujed krpelja.",
    cardEightSecondDescription: "USKORO!",
    questionBoxTitle: "Imate\npitanje?",
    questionBoxDescription: "Popunite kontakt formu i mi ćemo\nvam odgovoriti u najkraćem roku.",
    questionBoxNameLabel: "    Ime",
    questionBoxEmailLabel: "    Email",
    questionBoxMessageLabel: "    Poruka",
    questionBoxSubmitButton: "Pošaljite poruku",
    errorSubmit: "Greška prilikom slanja poruke, molimo Vas pokušajte kasnije!",
    successSubmit: "Uspešno ste poslali poruku!",
    aboutTitleOne: "Misija ",
    aboutTitleTwo: "Tick Detect\n",
    aboutTitleThree: "aplikacije",
    aboutParagraph: "•    Prikupljanje informacija o krpeljima i njihovim\n     staništima;\n\n•    Identifikacija područja gde su krpelji česti i izloženost\n     ljudi krpeljima;\n\n•    Informisanje stanovništva o krpeljima, pravilnom\n     uklanjanju i Lajmskoj bolesti;\n\n•    Proučavanje rizika od Lajmske bolesti;\n\n•    Mere za smanjenje verovatnoće ujeda krpelja tokom\n      boravka u prirodi.",
    importantTitlePartOne: "Važno je ",
    importantTitlePartTwo: "ne donositi brze zaključke.",
    importantParagraphOne: "Ujed krpelja ne mora biti katastrofan, preduzimanje odgovarajućih\n mera je ključno. Proveravajte se posle svakog boravka napolju, tuširajte se i perite kosu.",
    importantParagraphTwo: "Ako pronađete krpelja, donesite ga nama na analizu.",
    importantParagraphThree: "Ukoliko je krpelj inficiran, terapija antibioticima u prva dva meseca može sprečiti širenje\nbakterija u vašem organizmu i pojave simptoma Lajmske bolesti.",
    aboutDescriptionParagraphOne: " Ideja ove aplikacije",
    aboutDescriptionParagraphTwo: " jeste da se sve značajne informacije koncentrišu\n na jednom mestu, pružajući korisnicima osnovno znanje i mir. Ovde\n možete saznati o sve krpeljima, njihovim navikama, pravilnom\n uklanjanju krpelja i kako utvrditi da li je krpelj zaražen.",
    aboutDescriptionParagraphBrake: " Da biste pomogli drugima u okolini, ukoliko pronađete krpelja na\n sebi, svojoj deci ili ljubimcu,",
    aboutDescriptionParagraphThree: " pratite korake uklanjanja, napravite\n fotografiju i pošaljite je putem aplikacije. ",
    aboutDescriptionParagraphFour: "Ove informacije mogu\n upozoriti ljude da budu oprezniji u toj oblasti. Takođe možete doneti\n krpelja u našu laboratoriju radi identifikacije vrste i utvrđivanja\n prisustva infekcije.",
    aboutDescriptionInstruction: "Koraci za\nkorišćenje aplikacije",
    aboutBoxOneTitle: "Zakačio Vam se krpelj?",
    aboutBoxOneDescription: "Pravilno ga skinite ili posetite\nnajbližu ambulantu da ga skinu.",
    aboutBoxTwoTitle: "Identifikujte krpelja",
    aboutBoxTwoDescription: "Fotografišite krpelja i\npošaljite fotografiju\nputem aplikacije.",
    aboutBoxThreeTitle: "Da li je krpelj zaražen?",
    aboutBoxThreeDescription: "Donesite ili pošaljite poštom\nkrpelja u našu laboratoriju,\nkako bismo utvrdili da li u\nnjemu ima bakterija.",
    totalDetectedTicks: "Ukupan broj detektovanih krpelja",
    seeMore: "Vidi više..."
};  

export default stringsLatin;