// Modal.js (assuming you have created this component)
import React from 'react';
import './Modal.css'; // Your CSS file for styling
import { useLanguage } from '../LanguageContext';
import stringsLatin from '../ConstantsLatin';
import stringsCyrlic from '../ConstantsCyrilic';
import green from '../img/green.svg';
import yellow from '../img/yellow.svg';
import letter_x from '../img/letter-x.svg';
import red from '../img/red.svg';
const Modal = ({ isOpen, onClose, title, municipalities }) => {
  const { language, toggleLanguage } = useLanguage();
  const text = language === 'latin' ? stringsLatin : stringsCyrlic;
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
        <div onClick={onClose} class="close-button">
          <img className="letter-x"src={letter_x}></img>
        </div>
          <h2>{title}</h2>
          <p>{text.totalDetectedTicks}</p>
        </div>
        <div className="modal-body">
          <div className="modal-content">
          {Object.entries(municipalities).flatMap(([city, districts]) => {
          return (
            <div className="municipality" key={`${city}-${districts}`}>
              <span className="municipality-count">{city}</span>
              <span className="municipality-name">{districts}</span>
            </div>
          );
        })}
      
          </div>
        </div>
        <div className="modal-footer">
          <img className="modal-img" src={green} alt="Icon Description" />
          <p className="modal-p first-paragr">{text.mapLegendGreen}</p>
          <img  className="modal-img" src={yellow} alt="Icon Description" />
          <p className="modal-p second-paragr">{text.mapLegendYellow}</p>
          <img  className="modal-img" src={red} alt="Icon Description" />
          <p className="modal-p third-paragr">{text.mapLegendRed}</p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
