import { municipalityData } from "./TestConstants";
export const regionData = {
    'Beograd': { 
        'Barajevo': municipalityData['Barajevo'], 
        'Čukarica': municipalityData['Čukarica'], 
        'Grocka': municipalityData['Grocka'], 
        'Lazarevac': municipalityData['Lazarevac'], 
        'Mladenovac': municipalityData['Mladenovac'], 
        'Novi Beograd': municipalityData['Novi Beograd'], 
        'Obrenovac': municipalityData['Obrenovac'], 
        'Palilula': municipalityData['Palilula'], 
        'Rakovica': municipalityData['Rakovica'], 
        'Savski Venac': municipalityData['Savski Venac'], 
        'Sopot': municipalityData['Sopot'], 
        'Stari Grad': municipalityData['Stari Grad'], 
        'Surčin': municipalityData['Surčin'], 
        'Voždovac': municipalityData['Voždovac'], 
        'Vračar': municipalityData['Vračar'], 
        'Zemun': municipalityData['Zemun'], 
        'Zvezdara': municipalityData['Zvezdara'] 
    },
    'Severnobački_okrug': { 
        'Bačka Topola': municipalityData['Bačka Topola'], 
        'Mali Iđoš': municipalityData['Mali Iđoš'], 
        'Subotica': municipalityData['Subotica'] 
    },
    'Srednjebanatski_okrug': { 
        'Zrenjanin': municipalityData['Zrenjanin'], 
        'Nova Crnja': municipalityData['Nova Crnja'], 
        'Novi Bečej': municipalityData['Novi Bečej'], 
        'Sečanj': municipalityData['Sečanj'], 
        'Žitište': municipalityData['Žitište'] 
    },
    'Severnobanatski_okrug': { 
        'Ada': municipalityData['Ada'], 
        'Čoka': municipalityData['Čoka'], 
        'Kanjiža': municipalityData['Kanjiža'], 
        'Kikinda': municipalityData['Kikinda'], 
        'Novi Kneževac': municipalityData['Novi Kneževac'], 
        'Senta': municipalityData['Senta'] 
    },
    'Južnobanatski_okrug': { 
        'Alibunar': municipalityData['Alibunar'], 
        'Bela Crkva': municipalityData['Bela Crkva'], 
        'Kovačica': municipalityData['Kovačica'], 
        'Kovin': municipalityData['Kovin'], 
        'Opovo': municipalityData['Opovo'], 
        'Pancevo': municipalityData['Pancevo'], 
        'Plandište': municipalityData['Plandište'], 
        'Vršac': municipalityData['Vršac'], 
        'Zrenjanin': municipalityData['Zrenjanin'] 
    },
    'Zapadnobački_okrug': { 
        'Apatin': municipalityData['Apatin'], 
        'Kula': municipalityData['Kula'], 
        'Odžaci': municipalityData['Odžaci'], 
        'Sombor': municipalityData['Sombor'] 
    },
    'Južnobački_okrug': { 
        'Bač': municipalityData['Bač'], 
        'Bačka Palanka': municipalityData['Bačka Palanka'], 
        'Bački Petrovac': municipalityData['Bački Petrovac'], 
        'Beočin': municipalityData['Beočin'], 
        'Bečej': municipalityData['Bečej'], 
        'Vrbas': municipalityData['Vrbas'], 
        'Žabalj': municipalityData['Žabalj'], 
        'Novi Sad': municipalityData['Novi Sad'], 
        'Srbobran': municipalityData['Srbobran'], 
        'Temerin': municipalityData['Temerin'], 
        'Titel': municipalityData['Titel'], 
        'Sremski Karlovci': municipalityData['Sremski Karlovci'] 
    },
    'Sremski_okrug': { 
        'Inđija': municipalityData['Inđija'], 
        'Irig': municipalityData['Irig'], 
        'Pećinci': municipalityData['Pećinci'], 
        'Ruma': municipalityData['Ruma'], 
        'Sremska Mitrovica': municipalityData['Sremska Mitrovica'], 
        'Šid': municipalityData['Šid'], 
        'Stara Pazova': municipalityData['Stara Pazova'] 
    },
    'Mačvanski_okrug': { 
        'Bogatić': municipalityData['Bogatić'], 
        'Koceljeva': municipalityData['Koceljeva'], 
        'Loznica': municipalityData['Loznica'], 
        'Ljubovija': municipalityData['Ljubovija'], 
        'Mali Zvornik': municipalityData['Mali Zvornik'], 
        'Šabac': municipalityData['Šabac'], 
        'Vladimirci': municipalityData['Vladimirci'] 
    },
    'Kolubarski_okrug': { 
        'Lajkovac': municipalityData['Lajkovac'], 
        'Ljig': municipalityData['Ljig'], 
        'Mionica': municipalityData['Mionica'], 
        'Osečina': municipalityData['Osečina'], 
        'Ub': municipalityData['Ub'], 
        'Valjevo': municipalityData['Valjevo'] 
    },
    'Podunavski_okrug': { 
        'Smederevo': municipalityData['Smederevo'], 
        'Smederevska Palanka': municipalityData['Smederevska Palanka'], 
        'Velika Plana': municipalityData['Velika Plana'] 
    },
    'Braničevski_okrug': { 
        'Golubac': municipalityData['Golubac'], 
        'Kučevo': municipalityData['Kučevo'], 
        'Malo Crniće': municipalityData['Malo Crniće'], 
        'Petrovac na Mlavi': municipalityData['Petrovac na Mlavi'], 
        'Požarevac': municipalityData['Požarevac'], 
        'Veliko Gradište': municipalityData['Veliko Gradište'], 
        'Žagubica': municipalityData['Žagubica'], 
        'Žabari': municipalityData['Žabari'] 
    },
    'Šumadijski_okrug': { 
        'Aranđelovac': municipalityData['Aranđelovac'], 
        'Batočina': municipalityData['Batočina'], 
        'Knić': municipalityData['Knić'], 
        'Kragujevac': municipalityData['Kragujevac'], 
        'Rača': municipalityData['Rača'], 
        'Lapovo': municipalityData['Lapovo'], 
        'Topola': municipalityData['Topola'] 
    },
    'Pomoravski_okrug': { 
        'Ćuprija': municipalityData['Ćuprija'], 
        'Despotovac': municipalityData['Despotovac'], 
        'Jagodina': municipalityData['Jagodina'], 
        'Paraćin': municipalityData['Paraćin'], 
        'Rekovac': municipalityData['Rekovac'], 
        'Svilajnac': municipalityData['Svilajnac'] 
    },
    'Borski_okrug': { 
        'Bor': municipalityData['Bor'], 
        'Kladovo': municipalityData['Kladovo'], 
        'Majdanpek': municipalityData['Majdanpek'], 
        'Negotin': municipalityData['Negotin'] 
    },
    'Zaječarski_okrug': { 
        'Boljevac': municipalityData['Boljevac'], 
        'Knjaževac': municipalityData['Knjaževac'], 
        'Sokobanja': municipalityData['Sokobanja'], 
        'Zaječar': municipalityData['Zaječar'] 
    },
    'Zlatiborski_okrug': { 
        'Arilje': municipalityData['Arilje'], 
        'Bajina Bašta': municipalityData['Bajina Bašta'], 
        'Kosjerić': municipalityData['Kosjerić'], 
        'Nova Varoš': municipalityData['Nova Varoš'], 
        'Požega': municipalityData['Požega'], 
        'Priboj': municipalityData['Priboj'], 
        'Prijepolje': municipalityData['Prijepolje'], 
        'Sjenica': municipalityData['Sjenica'], 
        'Užice': municipalityData['Užice'] 
    },
    'Moravički_okrug': { 
        'Čačak': municipalityData['Čačak'], 
        'Gornji Milanovac': municipalityData['Gornji Milanovac'], 
        'Ivanjica': municipalityData['Ivanjica'], 
        'Lučani': municipalityData['Lučani'] 
    },
    'Raški_okrug': { 
        'Kraljevo': municipalityData['Kraljevo'], 
        'Novi Pazar': municipalityData['Novi Pazar'], 
        'Raška': municipalityData['Raška'], 
        'Tutin': municipalityData['Tutin'], 
        'Vrnjačka Banja': municipalityData['Vrnjačka Banja'] 
    },
    'Rasinski_okrug': { 
        'Aleksandrovac': municipalityData['Aleksandrovac'], 
        'Brus': municipalityData['Brus'], 
        'Ćićevac': municipalityData['Ćićevac'], 
        'Kruševac': municipalityData['Kruševac'], 
        'Trstenik': municipalityData['Trstenik'], 
        'Varvarin': municipalityData['Varvarin'] 
    },
    'Nišavski_okrug': { 
        'Aleksinac': municipalityData['Aleksinac'], 
        'Doljevac': municipalityData['Doljevac'], 
        'Merošina': municipalityData['Merošina'], 
        'Niš': municipalityData['Niš'], 
        'Ražanj': municipalityData['Ražanj'], 
        'Svrljig': municipalityData['Svrljig'] 
    },
    'Toplički_okrug': { 
        'Blace': municipalityData['Blace'], 
        'Kuršumlija': municipalityData['Kuršumlija'], 
        'Prokuplje': municipalityData['Prokuplje'], 
        'Žitorađa': municipalityData['Žitorađa'] 
    },
    'Pirotski_okrug': { 
        'Babušnica': municipalityData['Babušnica'], 
        'Bela Palanka': municipalityData['Bela Palanka'], 
        'Dimitrovgrad': municipalityData['Dimitrovgrad'], 
        'Pirot': municipalityData['Pirot'] 
    },
    'Jablanički_okrug': { 
        'Bojnik': municipalityData['Bojnik'], 
        'Crna Trava': municipalityData['Crna Trava'], 
        'Lebane': municipalityData['Lebane'], 
        'Leskovac': municipalityData['Leskovac'], 
        'Medveđa': municipalityData['Medveđa'], 
        'Vlasotince': municipalityData['Vlasotince'] 
    },
    'Pčinjski_okrug': { 
        'Bujanovac': municipalityData['Bujanovac'], 
        'Preševo': municipalityData['Preševo'], 
        'Vladičin Han': municipalityData['Vladičin Han'], 
        'Surdulica': municipalityData['Surdulica'], 
        'Trgovište': municipalityData['Trgovište'], 
        'Vranje': municipalityData['Vranje'] 
    },
    'Kosovski_okrug': { 
        'Gnjilane': municipalityData['Gnjilane'], 
        'Kosovo Polje': municipalityData['Kosovo Polje'], 
        'Lipljan': municipalityData['Lipljan'], 
        'Obilić': municipalityData['Obilić'], 
        'Priština': municipalityData['Priština'] 
    },
    'Pećki_okrug': { 
        'Dečani': municipalityData['Dečani'], 
        'Istok': municipalityData['Istok'], 
        'Klina': municipalityData['Klina'], 
        'Peć': municipalityData['Peć'] 
    },
    'Prizrenski_okrug': { 
        'Dragaš': municipalityData['Dragaš'], 
        'Orahovac': municipalityData['Orahovac'], 
        'Prizren': municipalityData['Prizren'], 
        'Suva Reka': municipalityData['Suva Reka'] 
    },
    'Kosovsko-Mitrovački_okrug': { 
        'Kosovska Mitrovica': municipalityData['Kosovska Mitrovica'], 
        'Leposavić': municipalityData['Leposavić'], 
        'Srbica': municipalityData['Srbica'], 
        'Zubin Potok': municipalityData['Zubin Potok'], 
        'Zvečan': municipalityData['Zvečan'] 
    },
    'Kosovsko-Pomoravski_okrug': { 
        'Gnjilane': municipalityData['Gnjilane'], 
        'Kamenica': municipalityData['Kamenica'], 
        'Novo Brdo': municipalityData['Novo Brdo'], 
        'Vitina': municipalityData['Vitina'] 
    }
};
export const regionDataCyrlic = {
    'Beograd': { 
        'Барајево': municipalityData['Barajevo'], 
        'Чукарица': municipalityData['Čukarica'], 
        'Гроцка': municipalityData['Grocka'], 
        'Лазаревац': municipalityData['Lazarevac'], 
        'Младеновац': municipalityData['Mladenovac'], 
        'Нови Београд': municipalityData['Novi Beograd'], 
        'Обреновац': municipalityData['Obrenovac'], 
        'Палилула': municipalityData['Palilula'], 
        'Раковица': municipalityData['Rakovica'], 
        'Савски Венац': municipalityData['Savski Venac'], 
        'Сопот': municipalityData['Sopot'], 
        'Стари Град': municipalityData['Stari Grad'], 
        'Сурчин': municipalityData['Surčin'], 
        'Вождовац': municipalityData['Voždovac'], 
        'Врачар': municipalityData['Vračar'], 
        'Земун': municipalityData['Zemun'], 
        'Звездара': municipalityData['Zvezdara']
    },
    'Severnobački_okrug': { 
        'Бачка Топола': municipalityData['Bačka Topola'], 
        'Мали Иђош': municipalityData['Mali Iđoš'], 
        'Суботица': municipalityData['Subotica'] 
    },
    'Srednjebanatski_okrug': { 
        'Зрењанин': municipalityData['Zrenjanin'], 
        'Нова Црња': municipalityData['Nova Crnja'], 
        'Нови Бечеј': municipalityData['Novi Bečej'], 
        'Сечањ': municipalityData['Sečanj'], 
        'Житиште': municipalityData['Žitište'] 
    },
    'Severnobanatski_okrug': { 
        'Ада': municipalityData['Ada'], 
        'Чока': municipalityData['Čoka'], 
        'Кањижа': municipalityData['Kanjiža'], 
        'Кикинда': municipalityData['Kikinda'], 
        'Нови Кнежевац': municipalityData['Novi Kneževac'], 
        'Сента': municipalityData['Senta'] 
    },
    'Južnobanatski_okrug': { 
        'Алибунар': municipalityData['Alibunar'], 
        'Бела Црква': municipalityData['Bela Crkva'], 
        'Ковачица': municipalityData['Kovačica'], 
        'Ковин': municipalityData['Kovin'], 
        'Опово': municipalityData['Opovo'], 
        'Панцево': municipalityData['Pancevo'], 
        'Пландиште': municipalityData['Plandište'], 
        'Вршац': municipalityData['Vršac'], 
        'Зрењанин': municipalityData['Zrenjanin'] 
    },
    'Zapadnobački_okrug': { 
        'Апатин': municipalityData['Apatin'], 
        'Кула': municipalityData['Kula'], 
        'Оџаци': municipalityData['Odžaci'], 
        'Сомбор': municipalityData['Sombor'] 
    },
    'Južnobački_okrug': { 
        'Бач': municipalityData['Bač'], 
        'Бачка Паланка': municipalityData['Bačka Palanka'], 
        'Бачки Петровац': municipalityData['Bački Petrovac'], 
        'Беочин': municipalityData['Beočin'], 
        'Бечеј': municipalityData['Bečej'], 
        'Врбас': municipalityData['Vrbas'], 
        'Жабаљ': municipalityData['Žabalj'], 
        'Нови Сад': municipalityData['Novi Sad'], 
        'Србобран': municipalityData['Srbobran'], 
        'Темерин': municipalityData['Temerin'], 
        'Тител': municipalityData['Titel'], 
        'Сремски Карловци': municipalityData['Sremski Karlovci'] 
    },
    'Sremski_okrug': { 
        'Инђија': municipalityData['Inđija'], 
        'Ириг': municipalityData['Irig'], 
        'Пећинци': municipalityData['Pećinci'], 
        'Рума': municipalityData['Ruma'], 
        'Сремска Митровица': municipalityData['Sremska Mitrovica'], 
        'Шид': municipalityData['Šid'], 
        'Стара Пазова': municipalityData['Stara Pazova'] 
    },
    'Mačvanski_okrug': { 
        'Богатић': municipalityData['Bogatić'], 
        'Коцељева': municipalityData['Koceljeva'], 
        'Лозница': municipalityData['Loznica'], 
        'Љубовија': municipalityData['Ljubovija'], 
        'Мали Зворник': municipalityData['Mali Zvornik'], 
        'Шабац': municipalityData['Šabac'], 
        'Владимирци': municipalityData['Vladimirci'] 
    },
    'Kolubarski_okrug': { 
        'Лајковац': municipalityData['Lajkovac'], 
        'Љиг': municipalityData['Ljig'], 
        'Мионица': municipalityData['Mionica'], 
        'Осечина': municipalityData['Osečina'], 
        'Уб': municipalityData['Ub'], 
        'Ваљево': municipalityData['Valjevo'] 
    },
    'Podunavski_okrug': { 
        'Смедерево': municipalityData['Smederevo'], 
        'Смедеревска Паланка': municipalityData['Smederevska Palanka'], 
        'Велика Плана': municipalityData['Velika Plana'] 
    },
    'Braničevski_okrug': { 
        'Голубац': municipalityData['Golubac'], 
        'Кучево': municipalityData['Kučevo'], 
        'Мало Црниће': municipalityData['Malo Crniće'], 
        'Петровац на Млави': municipalityData['Petrovac na Mlavi'], 
        'Пожаревац': municipalityData['Požarevac'], 
        'Велико Градиште': municipalityData['Veliko Gradište'], 
        'Жагубица': municipalityData['Žagubica'], 
        'Жабари': municipalityData['Žabari'] 
    },
    'Šumadijski_okrug': { 
        'Аранђеловац': municipalityData['Aranđelovac'], 
        'Баточина': municipalityData['Batočina'], 
        'Кнић': municipalityData['Knić'], 
        'Крагујевац': municipalityData['Kragujevac'], 
        'Рача': municipalityData['Rača'], 
        'Лапово': municipalityData['Lapovo'], 
        'Топола': municipalityData['Topola'] 
    },
    'Pomoravski_okrug': { 
        'Ћуприја': municipalityData['Ćuprija'], 
        'Деспотовац': municipalityData['Despotovac'], 
        'Јагодина': municipalityData['Jagodina'], 
        'Параћин': municipalityData['Paraćin'], 
        'Рековац': municipalityData['Rekovac'], 
        'Свилајнац': municipalityData['Svilajnac'] 
    },
    'Borski_okrug': { 
        'Бор': municipalityData['Bor'], 
        'Кладово': municipalityData['Kladovo'], 
        'Мајданпек': municipalityData['Majdanpek'], 
        'Неготин': municipalityData['Negotin'] 
    },
    'Zaječarski_okrug': { 
        'Бољевац': municipalityData['Boljevac'], 
        'Књажевац': municipalityData['Knjaževac'], 
        'Сокобања': municipalityData['Sokobanja'], 
        'Зајечар': municipalityData['Zaječar'] 
    },
    'Zlatiborski_okrug': { 
        'Ариље': municipalityData['Arilje'], 
        'Бајина Башта': municipalityData['Bajina Bašta'], 
        'Косјерић': municipalityData['Kosjerić'], 
        'Нова Варош': municipalityData['Nova Varoš'], 
        'Пожега': municipalityData['Požega'], 
        'Прибој': municipalityData['Priboj'], 
        'Пријепоље': municipalityData['Prijepolje'], 
        'Сјеница': municipalityData['Sjenica'], 
        'Ужице': municipalityData['Užice'] 
    },
    'Moravički_okrug': { 
        'Чачак': municipalityData['Čačak'], 
        'Горњи Милановац': municipalityData['Gornji Milanovac'], 
        'Ивањица': municipalityData['Ivanjica'], 
        'Лучани': municipalityData['Lučani'] 
    },
    'Raški_okrug': { 
        'Краљево': municipalityData['Kraljevo'], 
        'Нови Пазар': municipalityData['Novi Pazar'], 
        'Рашка': municipalityData['Raška'], 
        'Тутин': municipalityData['Tutin'], 
        'Врњачка Бања': municipalityData['Vrnjačka Banja'] 
    },
    'Rasinski_okrug': { 
        'Александровац': municipalityData['Aleksandrovac'], 
        'Брус': municipalityData['Brus'], 
        'Ћићевац': municipalityData['Ćićevac'], 
        'Крушевац': municipalityData['Kruševac'], 
        'Трстеник': municipalityData['Trstenik'], 
        'Варварин': municipalityData['Varvarin'] 
    },
    'Nišavski_okrug': { 
        'Алексинац': municipalityData['Aleksinac'], 
        'Дољевац': municipalityData['Doljevac'], 
        'Мерошина': municipalityData['Merošina'], 
        'Ниш': municipalityData['Niš'], 
        'Ражањ': municipalityData['Ražanj'], 
        'Сврљиг': municipalityData['Svrljig'] 
    },
    'Toplički_okrug': { 
        'Блаце': municipalityData['Blace'], 
        'Куршумлија': municipalityData['Kuršumlija'], 
        'Прокупље': municipalityData['Prokuplje'], 
        'Житорађа': municipalityData['Žitorađa'] 
    },
    'Pirotski_okrug': { 
        'Бабушница': municipalityData['Babušnica'], 
        'Бела Паланка': municipalityData['Bela Palanka'], 
        'Димитровград': municipalityData['Dimitrovgrad'], 
        'Пирот': municipalityData['Pirot'] 
    },
    'Jablanički_okrug': { 
        'Бојник': municipalityData['Bojnik'], 
        'Црна Трава': municipalityData['Crna Trava'], 
        'Лебане': municipalityData['Lebane'], 
        'Лесковац': municipalityData['Leskovac'], 
        'Медвеђа': municipalityData['Medveđa'], 
        'Власотинце': municipalityData['Vlasotince'] 
    },
    'Pčinjski_okrug': { 
        'Бујановац': municipalityData['Bujanovac'], 
        'Прешево': municipalityData['Preševo'], 
        'Владичин Хан': municipalityData['Vladičin Han'], 
        'Сурдулица': municipalityData['Surdulica'], 
        'Трговиште': municipalityData['Trgovište'], 
        'Врање': municipalityData['Vranje'] 
    },
    'Kosovski_okrug': { 
        'Гњилане': municipalityData['Gnjilane'], 
        'Косово Поље': municipalityData['Kosovo Polje'], 
        'Липљан': municipalityData['Lipljan'], 
        'Обилић': municipalityData['Obilić'], 
        'Приштина': municipalityData['Priština'] 
    },
    'Pećki_okrug': { 
        'Дечани': municipalityData['Dečani'], 
        'Исток': municipalityData['Istok'], 
        'Клина': municipalityData['Klina'], 
        'Пећ': municipalityData['Peć'] 
    },
    'Prizrenski_okrug': { 
        'Драгаш': municipalityData['Dragaš'], 
        'Ораховац': municipalityData['Orahovac'], 
        'Призрен': municipalityData['Prizren'], 
        'Сува Река': municipalityData['Suva Reka'] 
    },
    'Kosovsko-Mitrovački_okrug': { 
        'Косовска Митровица': municipalityData['Kosovska Mitrovica'], 
        'Лепосавић': municipalityData['Leposavić'], 
        'Србица': municipalityData['Srbica'], 
        'Зубин Поток': municipalityData['Zubin Potok'], 
        'Звечан': municipalityData['Zvečan'] 
    },
    'Kosovsko-Pomoravski_okrug': { 
        'Гњилане': municipalityData['Gnjilane'], 
        'Каменица': municipalityData['Kamenica'], 
        'Ново Брдо': municipalityData['Novo Brdo'], 
        'Витина': municipalityData['Vitina'] 
    }
};


export const translateRegionsLatin = {
    'Beograd': 'Beograd',
    'Severnobački_okrug': 'Severnobački okrug',
    'Srednjebanatski_okrug': 'Srednjebanatski okrug',
    'Severnobanatski_okrug': 'Severnobanatski okrug',
    'Južnobanatski_okrug': 'Južnobanatski okrug',
    'Zapadnobački_okrug': 'Zapadnobački okrug',
    'Južnobački_okrug': 'Južnobački okrug',
    'Sremski_okrug': 'Sremski okrug',
    'Mačvanski_okrug': 'Mačvanski okrug',
    'Kolubarski_okrug': 'Kolubarski okrug',
    'Podunavski_okrug': 'Podunavski okrug',
    'Braničevski_okrug': 'Braničevski okrug',
    'Šumadijski_okrug': 'Šumadijski okrug',
    'Pomoravski_okrug': 'Pomoravski okrug',
    'Borski_okrug': 'Borski okrug',
    'Zaječarski_okrug': 'Zaječarski okrug',
    'Zlatiborski_okrug': 'Zlatiborski okrug',
    'Moravički_okrug': 'Moravički okrug',
    'Raški_okrug': 'Raški okrug',
    'Rasinski_okrug': 'Rasinski okrug',
    'Nišavski_okrug': 'Nišavski okrug',
    'Toplički_okrug': 'Toplički okrug',
    'Pirotski_okrug': 'Pirotski okrug',
    'Jablanički_okrug': 'Jablanički okrug',
    'Pčinjski_okrug': 'Pčinjski okrug',
    'Kosovski_okrug': 'Kosovski okrug',
    'Pećki_okrug': 'Pećki okrug',
    'Prizrenski_okrug': 'Prizrenski okrug',
    'Kosovsko-Mitrovački_okrug': 'Kosovsko-Mitrovački okrug',
    'Kosovsko-Pomoravski_okrug': 'Kosovsko-Pomoravski okrug'
}
export const translateRegionCyrilic = {
    'Beograd': 'Београд',
    'Severnobački_okrug': 'Севернобачки округ',
    'Srednjebanatski_okrug': 'Средњебанатски округ',
    'Severnobanatski_okrug': 'Севернобанатски округ',
    'Južnobanatski_okrug': 'Јужнобанатски округ',
    'Zapadnobački_okrug': 'Западнобачки округ',
    'Južnobački_okrug': 'Јужнобачки округ',
    'Sremski_okrug': 'Сремски округ',
    'Mačvanski_okrug': 'Мачвански округ',
    'Kolubarski_okrug': 'Колубарски округ',
    'Podunavski_okrug': 'Подунавски округ',
    'Braničevski_okrug': 'Браничевски округ',
    'Šumadijski_okrug': 'Шумадијски округ',
    'Pomoravski_okrug': 'Поморавски округ',
    'Borski_okrug': 'Борски округ',
    'Zaječarski_okrug': 'Зајечарски округ',
    'Zlatiborski_okrug': 'Златиборски округ',
    'Moravički_okrug': 'Моравички округ',
    'Raški_okrug': 'Рашки округ',
    'Rasinski_okrug': 'Расински округ',
    'Nišavski_okrug': 'Нишавски округ',
    'Toplički_okrug': 'Топлички округ',
    'Pirotski_okrug': 'Пиротски округ',
    'Jablanički_okrug': 'Јабланички округ',
    'Pčinjski_okrug': 'Пчињски округ',
    'Kosovski_okrug': 'Косовски округ',
    'Pećki_okrug': 'Пећки округ',
    'Prizrenski_okrug': 'Призренски округ',
    'Kosovsko-Mitrovački_okrug': 'Косовскомитровачки округ',
    'Kosovsko-Pomoravski_okrug': 'Косовскопоморавски округ'
};
