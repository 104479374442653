
export const regionNumbers = {
    'Beograd':'6',
    'Severnobački_okrug':'0',
    'Srednjebanatski_okrug':'0',
    'Severnobanatski_okrug':'1',
    'Južnobanatski_okrug':'1',
    'Zapadnobački_okrug':'1',
    'Južnobački_okrug':'35',
    'Sremski_okrug':'13',
    'Mačvanski_okrug':'3',
    'Kolubarski_okrug':'1',
    'Podunavski_okrug':'0',
    'Braničevski_okrug':'0',
    'Šumadijski_okrug':'0',
    'Pomoravski_okrug':'0',
    'Borski_okrug':'0',
    'Zaječarski_okrug':'1',
    'Zlatiborski_okrug':'0',
    'Moravički_okrug':'0',
    'Raški_okrug':'0',
    'Rasinski_okrug':'0',
    'Nišavski_okrug':'1',
    'Toplički_okrug':'0',
    'Pirotski_okrug':'0',
    'Jablanički_okrug':'0',
    'Pčinjski_okrug':'1',
    'Kosovski_okrug':'0',
    'Pećki_okrug':'0',
    'Prizrenski_okrug':'0',
    'Kosovsko-Mitrovački_okrug':'0',
    'Kosovsko-Pomoravski_okrug':'0'
};
export const municipalityData = {
    'Barajevo': 1,
    'Čukarica': 0,
    'Grocka': 0,
    'Lazarevac': 0,
    'Mladenovac': 0,
    'Novi Beograd': 1,
    'Obrenovac': 0,
    'Palilula': 3,
    'Rakovica': 0,
    'Savski Venac': 0,
    'Sopot': 0,
    'Stari Grad': 0,
    'Surčin': 0,
    'Voždovac': 1,
    'Vračar': 0,
    'Zemun': 0,
    'Zvezdara': 0,
    'Bačka Topola': 0,
    'Mali Iđoš': 0,
    'Subotica': 0,
    'Zrenjanin': 0,
    'Nova Crnja': 0,
    'Novi Bečej': 0,
    'Sečanj': 0,
    'Žitište': 0,
    'Ada': 0,
    'Čoka': 0,
    'Kanjiža': 0,
    'Kikinda': 1,
    'Novi Kneževac': 0,
    'Senta': 0,
    'Alibunar': 0,
    'Bela Crkva': 0,
    'Kovačica': 0,
    'Kovin': 1,
    'Opovo': 0,
    'Pancevo': 0,
    'Plandište': 0,
    'Vršac': 0,
    'Apatin': 0,
    'Kula': 1,
    'Odžaci': 0,
    'Sombor': 0,
    'Bač': 0,
    'Bačka Palanka': 0,
    'Bački Petrovac': 0,
    'Beočin': 5,
    'Bečej': 1,
    'Vrbas': 0,
    'Žabalj': 0,
    'Novi Sad': 23,
    'Srbobran': 0,
    'Temerin': 0,
    'Titel': 0,
    'Sremski Karlovci': 6,
    'Inđija': 4,
    'Irig': 5,
    'Pećinci': 0,
    'Ruma': 0,
    'Sremska Mitrovica': 3,
    'Šid': 1,
    'Stara Pazova': 0,
    'Bogatić': 0,
    'Koceljeva': 0,
    'Loznica': 2,
    'Ljubovija': 0,
    'Mali Zvornik': 1,
    'Šabac': 0,
    'Vladimirci': 0,
    'Lajkovac': 0,
    'Ljig': 0,
    'Mionica': 0,
    'Osečina': 0,
    'Ub': 0,
    'Valjevo': 1,
    'Smederevo': 0,
    'Smederevska Palanka': 0,
    'Velika Plana': 0,
    'Golubac': 0,
    'Kučevo': 0,
    'Malo Crniće': 0,
    'Petrovac na Mlavi': 0,
    'Požarevac': 0,
    'Veliko Gradište': 0,
    'Žagubica': 0,
    'Žabari': 0,
    'Aranđelovac': 0,
    'Batočina': 0,
    'Knić': 0,
    'Kragujevac': 0,
    'Rača': 0,
    'Lapovo': 0,
    'Topola': 0,
    'Ćuprija': 0,
    'Despotovac': 0,
    'Jagodina': 0,
    'Paraćin': 0,
    'Rekovac': 0,
    'Svilajnac': 0,
    'Bor': 0,
    'Kladovo': 0,
    'Majdanpek': 0,
    'Negotin': 0,
    'Boljevac': 1,
    'Knjaževac': 0,
    'Sokobanja': 0,
    'Zaječar': 0,
    'Arilje': 0,
    'Bajina Bašta': 0,
    'Kosjerić': 0,
    'Nova Varoš': 0,
    'Požega': 0,
    'Priboj': 0,
    'Prijepolje': 0,
    'Sjenica': 0,
    'Užice': 0,
    'Čačak': 0,
    'Gornji Milanovac': 0,
    'Ivanjica': 0,
    'Lučani': 0,
    'Kraljevo': 0,
    'Novi Pazar': 0,
    'Raška': 0,
    'Tutin': 0,
    'Vrnjačka Banja': 0,
    'Aleksandrovac': 0,
    'Brus': 0,
    'Ćićevac': 0,
    'Kruševac': 0,
    'Trstenik': 0,
    'Varvarin': 0,
    'Aleksinac': 0,
    'Doljevac': 0,
    'Merošina': 0,
    'Niš': 1,
    'Ražanj': 0,
    'Svrljig': 0,
    'Blace': 0,
    'Kuršumlija': 0,
    'Prokuplje': 0,
    'Žitorađa': 0,
    'Babušnica': 0,
    'Bela Palanka': 0,
    'Dimitrovgrad': 0,
    'Pirot': 0,
    'Bojnik': 0,
    'Crna Trava': 0,
    'Lebane': 0,
    'Leskovac': 0,
    'Medveđa': 0,
    'Vlasotince': 0,
    'Bujanovac': 0,
    'Preševo': 0,
    'Vladičin Han': 0,
    'Surdulica': 0,
    'Trgovište': 0,
    'Vranje': 1,
    'Gnjilane': 0,
    'Kosovo Polje': 0,
    'Lipljan': 0,
    'Obilić': 0,
    'Priština': 0,
    'Dečani': 0,
    'Istok': 0,
    'Klina': 0,
    'Peć': 0,
    'Dragaš': 0,
    'Orahovac': 0,
    'Prizren': 0,
    'Suva Reka': 0,
    'Kosovska Mitrovica': 0,
    'Leposavić': 0,
    'Srbica': 0,
    'Zubin Potok': 0,
    'Zvečan': 0,
    'Kamenica': 0,
    'Novo Brdo': 0,
    'Vitina': 0
};   

export const regionColors = {
    "Beograd":'#00ce7c',
    "Severnobački_okrug":'#00ce7c',
    "Srednjebanatski_okrug":'#00ce7c',
    "Severnobanatski_okrug":'#00ce7c',
    "Južnobanatski_okrug":'#00ce7c',
    "Zapadnobački_okrug":'#00ce7c',
    "Južnobački_okrug":'#00ce7c',
    "Sremski_okrug":'#00ce7c',
    "Mačvanski_okrug":'#00ce7c',
    "Kolubarski_okrug":'#00ce7c',
    "Podunavski_okrug":'#00ce7c',
    "Braničevski_okrug":'#00ce7c',
    "Šumadijski_okrug":'#00ce7c',
    "Pomoravski_okrug":'#00ce7c',
    "Borski_okrug":'#00ce7c',
    "Zaječarski_okrug":'#00ce7c',
    "Zlatiborski_okrug":'#00ce7c',
    "Moravički_okrug":'#00ce7c',
    "Raški_okrug":'#00ce7c',
    "Rasinski_okrug":'#00ce7c',
    "Nišavski_okrug":'#00ce7c',
    "Toplički_okrug":'#00ce7c',
    "Pirotski_okrug":'#00ce7c',
    "Jablanički_okrug":'#00ce7c',
    "Pčinjski_okrug":'#00ce7c',
    "Kosovski_okrug":'#00ce7c',
    "Pećki_okrug":'#00ce7c',
    "Prizrenski_okrug":'#00ce7c',
    "Kosovsko-Mitrovački_okrug":'#00ce7c',
    "Kosovsko-Pomoravski_okrug":'#00ce7c'
};