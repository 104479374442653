import React, { useRef, useEffect } from 'react';
import { constants, stateCode, drawPath } from './constants';

const SerbiaMap = ({
  size = '100vw',  // Default to 100vw if size is not provided
  mapColor,
  strokeColor,
  strokeWidth,
  hoverColor,
  onSelect,
  regionColors,
  regionNumbers
}) => {
  const mapStyle = {
    width: size,  // Use the size prop directly
    fill: mapColor || constants.MAPCOLOR,
    stroke: strokeColor || constants.STROKE_COLOR,
    strokeWidth: strokeWidth || constants.STROKE_WIDTH,
  };

  const svgRef = useRef(null);

  useEffect(() => {
    const svg = svgRef.current;
    // Cleanup function to remove all text elements before re-adding them
    const existingTexts = svg.querySelectorAll('text');
    existingTexts.forEach(text => text.remove());

    if (svg && regionNumbers) {
      Object.keys(regionNumbers).forEach((stateCode) => {
        const path = svg.querySelector(`#${stateCode}`);
        if(stateCode == "Sremski_okrug" || stateCode=="Mačvanski_okrug"){
          const textBox = document.createElementNS("http://www.w3.org/2000/svg", "text");
          const number = document.createTextNode(regionNumbers[stateCode]);
          textBox.appendChild(number);

          const box = path.getBBox();
          const centerX = box.x + box.width / 2;
          const centerY = box.y + box.height / 2 - 15;

          textBox.setAttribute('x', centerX);
          textBox.setAttribute('y', centerY);
          textBox.setAttribute('text-anchor', 'middle');
          textBox.setAttribute('alignment-baseline', 'central');
          textBox.setAttribute('fill', 'white'); // You can customize this as needed
          textBox.setAttribute('stroke', 'none'); // You can customize this as needed
          textBox.setAttribute('color', 'white'); // You can customize this as needed
          textBox.setAttribute('font-size', '25'); // You can customize this as needed
          textBox.setAttribute('pointer-events', 'none'); // You can customize this as needed
          svg.appendChild(textBox);
        }
        else if (path && stateCode) {
          const textBox = document.createElementNS("http://www.w3.org/2000/svg", "text");
          const number = document.createTextNode(regionNumbers[stateCode]);
          textBox.appendChild(number);

          const box = path.getBBox();
          const centerX = box.x + box.width / 2;
          const centerY = box.y + box.height / 2;

          textBox.setAttribute('x', centerX);
          textBox.setAttribute('y', centerY);
          textBox.setAttribute('text-anchor', 'middle');
          textBox.setAttribute('alignment-baseline', 'central');
          textBox.setAttribute('fill', 'white'); // You can customize this as needed
          textBox.setAttribute('stroke', 'none'); // You can customize this as needed
          textBox.setAttribute('color', 'white'); // You can customize this as needed
          textBox.setAttribute('font-size', '25'); // You can customize this as needed
          textBox.setAttribute('pointer-events', 'none'); // You can customize this as needed
          svg.appendChild(textBox);
        } else {
          console.error(`Path not found for the state code: ${stateCode}`);
        }
      });
    }
  }, [regionNumbers]); // Dependency array ensures effect runs only when regionNumbers change

  const handleMouseEnter = (hoverStateId) => {
    const path = document.getElementById(hoverStateId);
    if (path) {
      path.style.fill = hoverColor || constants.HOVERCOLOR;
    }
  };

  const handleMouseLeave = (hoverStateId) => {
    const path = document.getElementById(hoverStateId);
    if (path) {
      path.style.fill = regionColors[hoverStateId] || mapColor || constants.MAPCOLOR;
    }
  };

  return (
    <div className="map" style={mapStyle}>
      <svg ref={svgRef} version="1.1" id="svg2" x="0px" y="0px" viewBox="-20 -20 1800 850">
        {stateCode.map((stateCode, index) => (
          <path
            key={index}
            onClick={() => onSelect(stateCode)}
            onMouseEnter={() => handleMouseEnter(stateCode)}
            onMouseLeave={() => handleMouseLeave(stateCode)}
            id={stateCode}
            d={drawPath[stateCode]}
            fill={regionColors[stateCode] || mapColor}
          />
        ))}
      </svg>
    </div>
  );
};

export default SerbiaMap;
