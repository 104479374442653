import React, {useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import SerbiaMap from './utils/SerbiaMap';
import './Home.css';
import InstaFeed from './utils/InstaFeed'; // Adjust the path if necessary
import 'bootstrap/dist/css/bootstrap.min.css';
import phone from './img/phone-call.png'
import emailLogo from './img/email.png'
import logo from './img/TickDetectLogo.png';
import logoFooter from './img/TickDetectLogoFooter.png';
import logoInstitute from './img/logo-institute.png';
import logoInstituteCyr from './img/logo-institute-cr.png'
import { useLanguage } from './LanguageContext';
import stringsLatin from './ConstantsLatin';
import stringsCyrlic from './ConstantsCyrilic';
import frontVideo from './img/TickPreventionVideo.mp4';
import videoBoxFirst from './img/video-box-first.svg';
import hamburger from './img/hamburger.svg';

import videoBoxSecond from './img/video-box-second.svg';
import green from './img/green.svg';
import yellow from './img/yellow.svg';
import red from './img/red.svg';
import Modal from './utils/Modal';
import {regionData} from './utils/RegionCities'
import {regionDataCyrlic} from './utils/RegionCities'
import {translateRegionsLatin} from './utils/RegionCities'
import {translateRegionCyrilic} from './utils/RegionCities'
import {regionNumbers} from './utils/TestConstants'
import {regionColors} from './utils/TestConstants'
import videoBoxThird from './img/video-box-third.svg';
import tickDetectLogo from './img/TickDetectIcon.ico'
import phoneImage from './img/Tick-App.png'
import unfilledArrow from './img/arrow-unfilled.svg'
import filledArrow from './img/arrow-filled.svg'
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
    function clickNav(){
        toggleLanguage();
        toggleOffcanvas();
    }
    
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
    const { language, toggleLanguage } = useLanguage();
    const text = language === 'latin' ? stringsLatin : stringsCyrlic;
    const footerLogo = language =='latin' ? logoInstitute : logoInstituteCyr;
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState(null);
        const [isOpen, setIsOpen] = useState(false);

        const toggleOffcanvas = () => {
            setIsOpen(!isOpen);
        };
        const handleRegionClick = (regionCode) => {
      document.body.classList.add('no-scroll');
      setSelectedRegion(regionCode);
      setModalOpen(true);
    };
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

    useEffect(() => {
        
        const handleResize = () => {            
            setIsMobile(window.innerWidth <= 480);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        
    }, []);
    const closeModal = () => {
      document.body.classList.remove('no-scroll');
      setModalOpen(false);
      setSelectedRegion(null);
    };
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const handleCardClick = () => {
        window.location.href = "/about";
    }
    const handleCardClickRed = () => {
        window.location.href = "https://krpelji.nivns.edu.rs/mob/index.html";
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        const url = 'https://krpelji.nivns.edu.rs/messages';
        console.log(name);
        console.log(email);
        console.log(message);
        const payload = {
            name: name,
            email: email,
            message: message
        };
        console.log(JSON.stringify(payload))
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        
        if (response.status !== 200 && response.status !== 201) {
            console.error(`HTTP error! status: ${response.status}`);
            toast.error(text.errorSubmit);
            setName('');
            setEmail('');
            setMessage('');
        } else {
            toast.success(text.successSubmit);
            setName('');
            setEmail('');
            setMessage('');
        }
        
    };


    return (        
        <div className="bodyHome" onClick={() => isModalOpen && closeModal()}>
            <Modal
        isOpen={isModalOpen} 
        onClose={closeModal} 
        title={language === 'latin' ? translateRegionsLatin[selectedRegion] : translateRegionCyrilic[selectedRegion]} 
        municipalities={language === 'latin' ? regionData[selectedRegion] : regionDataCyrlic[selectedRegion] || {}}
      />
      
                <Helmet>                    
                    <link rel="icon" type="image/x-icon" href={tickDetectLogo} />
                </Helmet>   
                <nav className="navbar navbar-expand-sm navbar-light bg-white fixed-top">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={logo} alt="Tick Detect Logo" />
                </a>
                <div className="navbar-toggler" onClick={toggleOffcanvas}
                        aria-controls="offcanvasNavbar" aria-expanded={isOpen} aria-label="Toggle navigation">
                    <img className="burger" src={hamburger}></img>
                </div>

                <div className={`offcanvas offcanvas-start ${isOpen ? 'show' : ''} d-sm-none`} tabIndex="-1" id="offcanvasNavbar"
                     aria-labelledby="offcanvasNavbarLabel" style={{visibility: isOpen ? 'visible' : 'hidden'}}>
                    <div className="offcanvas-header">
                        <div  className="btn-close btn-close-white" onClick={toggleOffcanvas} aria-label="Close"></div>
                    </div>
                    <div className="offcanvas-body">
  <div className="footer-upper nav-mob-header">
    
    <div className="footer-logo">
    <a  href="/">
    <img src={logoFooter} className="nav-brand-mob" alt="Tick Detect Logo" />
    </a>    
        </div>
    <div className="footer-links nav-mobile">
<p><Link to="/about" className="footer-link nav-font">{text.navbarAbout}</Link></p>
<p><Link to="/contact" className="footer-link contact-link nav-font">{text.navbarContact}</Link></p>
<button className="nav-item nav-link nav-lang-mob" onClick={clickNav}>
                        LAT <span className="pipe-color">|</span> ЋИР
                    </button>
</div>
<div className="canvas-off"></div>


    <div className="footer-contact nav-mob ">
    <div className="contact-item ">
    <img className="icon" src={emailLogo} alt="email" />
    
    <p className="nav-font">pronadjikrpelja@niv.ns.ac.rs</p>
  </div>
  <div className="contact-item contact-phone">
    <img className="icon" src={phone} alt="phone" />
    <p>+381 21 489 53 30</p>
  </div>
</div>

    <div className="footer-institute-logo nav-inst-logo">
      <img src={footerLogo} alt="Institut Logo" />
    </div>
    
                        </div>
                    </div>
                </div>

                <div className="collapse navbar-collapse justify-content-end d-none d-sm-block" id="navbarSupportedContent">
                <div className="navbar-nav ms-auto">
                    <Link className="nav-item nav-link" to="/about">{text.navbarAbout}</Link>
                    <Link className="nav-item nav-link" to="/contact">{text.navbarContact}</Link>
                    <button className="nav-item nav-link" onClick={toggleLanguage}>
                        LAT <span className="pipe-color">|</span> ЋИР
                    </button>
                </div>
                </div>
            </div>
        </nav>
        {isMobile ? (
                <div className="mob-main">
                    {/* Render 3 containers for mobile */}
                    <div className="video-mob">
                    
                            <video class="video-mob-manip"
                                autoPlay={true} 
                                loop={true}
                                controls={false} 
                                playsInline
                                muted >
                                <source src={frontVideo} type="video/mp4" />
                            </video>    
                    </div>
                    <div className="video-mob-text">
                        <h3>{text.jumbotronTitle}</h3>
                        <p>{text.jumbotronDescription}</p>
                        <a href="https://krpelji.nivns.edu.rs/mobilni">
                        <button className="question-button mobile-video">{text.jumbotronSlideTwoButton}</button>
                        </a>
                    </div>
                    <div className="mob-slide-pic">
                    <div className="box first-box mob-first">
                                <p className="first-box-text first-text-mob">{text.jumbotronSlideOne}</p>
                            </div>       
                            <div className="box second-box mob-first">
                                <p className="second-box-text second-mob-text">{text.jumbotronSlideTwo}</p>
                                <Link to="/about" className="button-jumbotron button-slide second-button-mob">{text.jumbotronButton}</Link>
                            </div>                 
                            <h3 className="third-text-mob">{text.jumbotronSlideThree}</h3>
                            <a className="pargraph-mob-a"href="https://krpelji.nivns.edu.rs/mob/AppText/StaAkoSeKrpeljZakaci.html">
                            <p className="paragraph-mob">{text.readMore}</p>
                            </a>

                    </div>
                    <div className="box third-box third-box-mob">
                                <div className="box third-box-inner-box inner-box-mob" onMouseDown={handleCardClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                    <div className="content-with-svg">
                                        <div className="mob-wrapper">
                                            <img className="icon-third-paragraph" src={videoBoxFirst}></img>
                                            <div className="mob-box-text">
                                            <p className="mob-title">{text.videoSlideOneTitle}</p>
                                            <p className="mob-description">{text.videoSlideOneDescription}</p>
                                        </div>
                                        </div>
                                        <div className="mob-wrapper">
                                            <img className="icon-third-paragraph" src={videoBoxSecond}></img>
                                            <div className="mob-box-text sec-paragr">
                                            <p className="mob-title">{text.videoSlideTwoTitle}</p>
                                            <p className="mob-description">{text.videoSlideTwoDescription}</p>
                                        </div>
                                        </div>
                                        <div className="mob-wrapper">
                                            <img className="icon-third-paragraph" src={videoBoxThird}></img>
                                            <div className="mob-box-text">
                                            <p className="mob-title">{text.videoSlideThreeTitle}</p>
                                            <p className="mob-description">{text.videoSlideThreeDescription}</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                    
                </div>
                
            ) : (
                <><div className="main-content">
                        <div className="jumbo-main">
                            <h1>
                                {text.jumbotronTitle.split(' ').slice(0, -1).join(' ')}{' '}
                                {text.jumbotronTitle.split(' ').slice(-1)}
                                <Link to="/about" className="button-jumbotron">{text.navbarAbout}</Link>
                            </h1>
                            <p className="jumbo-paragraph">{text.jumbotronDescription}</p>
                        </div>

                        <div className="box-container">
                            <div className="box first-box">
                                <p className="first-box-text">{text.jumbotronSlideOne}</p>
                            </div>
                            <div className="box second-box">
                                <p className="second-box-text">{text.jumbotronSlideTwo}</p>
                                <button onMouseDown={handleCardClickRed} className="button-jumbotron button-slide">{text.jumbotronSlideTwoButton}</button>
                            </div>
                            <div className="box third-box">
                                <div className="box third-box-inner-box" onMouseDown={handleCardClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                    <div className="content-with-svg">
                                        <p className="second-box-text">{text.jumbotronSlideThree}</p>
                                        <img className="arrow-div" src={isHovered ? filledArrow : unfilledArrow}
                                            alt="Arrow"></img>
                                    </div>
                                    <p className="second-box-second-text">{text.readMore}</p>
                                </div>

                            </div>
                            
                        </div>
                    </div><div className="video-container">

                            <video w autoPlay={true} muted={true} loop>
                                <source src={frontVideo} type="video/mp4" />

                            </video>
                            <div className="box-overlay">
                                <div className="video-box">
                                    <div className="video-box-content">
                                        <div className="video-box-icon">
                                            <img className="video-box-image" src={videoBoxFirst} alt="Icon Description" />
                                        </div>
                                        <div className="video-box-text">
                                            <p className="video-box-title">{text.videoSlideOneTitle}</p>
                                            <p className="video-box-description">{text.videoSlideOneDescription}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="video-box">
                                    <div className="video-box-content">
                                        <div className="video-box-icon">
                                            <img className="video-box-image" src={videoBoxSecond} alt="Icon Description" />
                                        </div>
                                        <div className="video-box-text">
                                            <p className="video-box-title">{text.videoSlideTwoTitle}</p>
                                            <p className="video-box-description">{text.videoSlideTwoDescription}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="video-box">
                                    <div className="video-box-content">
                                        <div className="video-box-icon">
                                            <img className="video-box-image" src={videoBoxThird} alt="Icon Description" />
                                        </div>
                                        <div className="video-box-text">
                                            <p className="video-box-title">{text.videoSlideThreeTitle}</p>
                                            <p className="video-box-description">{text.videoSlideThreeDescription}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div></>
            )}

            <div className="map-container">
                <div className="modal-div">

      </div>                
                <div className="map-app-description">
                    <div className="phone-container">
                    <img className="phone-image" src={phoneImage} alt="Tick Detect App" />
                    </div>
                    <div className="application-text-content">
      <div className="application-text-box">
        <h2>{text.applicationTitleOne}</h2>
        <p>{text.applicationDescriptionOne}</p>
      </div>
      <div className="application-text-box">
        <h2>{text.applicationTitleTwo}</h2>
        <p>{text.applicationDescriptionTwo}</p>
      </div>
      <div className="application-text-box">
        <h2>{text.applicationTitleThree}</h2>
        <p className="para-margin">{text.applicationDescriptionThree}</p>        
      </div>
      <Link to="/about" className="application-button">{text.applicationButton}</Link>      

    </div>            
                </div>  
    <div className="statistics-map-container">
    <div className="statistics-container">
        <div className="user-statistics">
            <h2>74</h2>
            <p>{text.mapNumberOfUsers}</p>
        </div>
        <div className="tick-statistics">
            <h2>72</h2>
            <p>{text.mapNumberOfDetection}</p>
        </div>
        <div className="semaphore-container">
            <h3>{text.mapLegendTitle}</h3>
            <div className="semaphore-level high">
                <div className="icon-background">
                <img className="map-image" src={red} alt="Icon Description" />
                </div>
                <span className="legend-text">{text.mapLegendRed}</span>
            </div>
            <div className="semaphore-level medium">
                <div className="icon-background">
                <img className="map-image" src={yellow} alt="Icon Description" />
                </div>
                <span className="legend-text">{text.mapLegendYellow}</span>
            </div>
            <div className="semaphore-level low">
                <div className="icon-background">
                <img className="map-image"  src={green} alt="Icon Description" />
                </div>
                <span className="legend-text">{text.mapLegendGreen}</span>
            </div>
            <p className="instruction-text">{text.mapLegendDescription}</p>

        </div>
    </div>
    
    <div className="map-placeholder" >
    <SerbiaMap 
    className="map-srb"   
      regionNumbers={regionNumbers}
  mapColor="#ffffff"
  strokeColor="#000000"
  strokeWidth={2}
  hoverColor="#303030"
  onSelect={handleRegionClick}
  regionColors={regionColors}
>

</SerbiaMap>
    </div>
</div>                
                <div className="preventive-div">
                    <div className="preventive-text-content">
                         <h2>{text.preventionTitle}</h2>
                         <p>{text.preventionDescription}</p>
                    </div>
                         <Link onClick={handleCardClickRed} className="preventive-button">{text.jumbotronSlideTwoButton}
                         </Link>
                </div>  
            
          
            </div>
            <div className="connect-div">
                <div className="connect-title-div">
                    <h2>{text.newsTitle}</h2>
                    <p>{text.newsDescription}</p>
                </div>
                <InstaFeed></InstaFeed>
                <a href='https://www.instagram.com/pronadji_krpelja/'>
                <p className="paragraph-mob visib">{text.seeMore}</p>
                </a>
                

                <div className="connect-question-div main-connection">
                    <div className="connect-question-text-div">
                        <h3>{text.questionBoxTitle}</h3>
                        <p>{text.questionBoxDescription}</p>
                    </div>
        <form className="connect-question-form-div" onSubmit={handleSubmit}>
            <input value={name} onChange={(e) => setName(e.target.value)} required type="text" minLength="2" maxLength="100" className="line-input" placeholder={text.questionBoxNameLabel}/>
            <input value={email} onChange={(e) => setEmail(e.target.value)} required type="email" minLength="2" maxLength="100" className="line-input" placeholder={text.questionBoxEmailLabel}/>
            <textarea value={message} onChange={(e) => setMessage(e.target.value)} required type="text" className="line-input message-input" maxLength="500" placeholder={text.questionBoxMessageLabel}/>
            <button type="submit" className="question-button">{text.questionBoxSubmitButton}</button>

        </form>
                    
                </div>

            </div>
            <footer className="footer-container foot-main">
  <div className="footer-upper">
    <div className="footer-logo">        
    <a  href="/">
    <img src={logoFooter} alt="Tick Detect Logo" />
    </a>    
    </div>
    <div className="footer-contact">
  <div className="contact-item">
    <img className="icon" src={emailLogo} alt="email" />
    <p>pronadjikrpelja@niv.ns.ac.rs</p>
  </div>
  <div className="contact-item contact-phone">
    <img className="icon" src={phone} alt="phone" />
    <p>+381 21 489 53 30</p>
  </div>
</div>
<div className="footer-links">
<p><Link to="/about" className="footer-link">{text.navbarAbout}</Link></p>
<p><Link to="/contact" className="footer-link contact-link">{text.navbarContact}</Link></p>
</div>
    <div className="footer-institute-logo">
      <img src={footerLogo} alt="Institut Logo" />
    </div>
  </div>
  <div className="footer-line"></div>
  <div className="footer-lower">
    <p>© 2024 Tick Detect</p>
    <p><Link to="http://weareshortcut.com" className="footer-shortcut">Design and development:<span className="green-title separator"><b>Shortcut</b></span></Link></p>
  </div>
</footer>
        </div>
    );
};

export default Home;
